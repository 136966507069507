import { defineComponent } from '@vue/composition-api';
import { WX_LOGIN } from '../../http/api';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
  setup() {
    const getQueryString = name => {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      const result = window.location.search.substring(1).match(reg);

      if (result != null) {
        return decodeURIComponent(result[2]);
      }

      return null;
    };

    let code = getQueryString("code");

    if (code === "") {
      return;
    }

    const route = useRoute();
    const router = useRouter();
    let redirect = route.query.redirect;
    WX_LOGIN({
      code: code,
      origin_id: ""
    }).then(res => {
      if (res.data == "") {
        router.push(redirect || "/");
      } else if (res.data.data.token !== '') {
        localStorage.setItem("token", res.data.data.token);
        router.push(redirect || "/");
      }
    });
  }

});